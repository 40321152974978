<template>
  <div>
    <table>
      <thead>
        <tr>
          <!-- <th rowspan="2">SEMANA</th> -->
          <th rowspan="2">Sesión</th>
          <!-- <th rowspan="2">FECHA</th> -->
          <th rowspan="1" colspan="2">¿QUÉ VA A APRENDER EL ESTUDIANTE?</th>
          <th rowspan="1" colspan="2">¿CÓMO LO VA A APRENDER?</th>
          <th rowspan="1" colspan="2">¿CÓMO LO EJERCITARÁ?</th>
          <!-- <th rowspan="2" colspan="1">EVALUACIONES</th> -->
          <!-- <th rowspan="2" colspan="1">¿CÓMO RETROALIMENTARÁ SU APRENDIZAJE?</th> -->
          <th
            rowspan="2"
            colspan="1"
            title="OBSERVACIONES DE LA SESIÓN UNA VEZ REALIZADA LA CLASE"
            v-if="user.is_professor && allows_crud"
          >
            OBS
          </th>
          <!-- <th v-if="allows_crud" :rowspan="2" title="OBSERVACIONES"></th> -->
        </tr>
        <tr>
          <th>
            {{
              $getVisibleNames(
                "manual.criterio_de_evaluacion",
                true,
                "Criterios de Evaluación"
              )
            }}
            <!-- CRITERIOS DE EVALUACIÓN -->
          </th>
          <th>CONTENIDOS RELEVANTES</th>
          <th>
            {{
              $getVisibleNames(
                "mesh.methodologicalstrategy",
                true,
                "Estrategias Metodológicas"
              ).toUpperCase()
            }}
          </th>
          <th>ACTIVIDADES EN AULA</th>
          <th>
            {{
              $getVisibleNames(
                "mesh.autonomousteachingactivity",
                false,
                "Actividad de Trabajo Autónomo"
              ).toUpperCase()
            }}
          </th>
          <th>BIBLIOGRAFÍA BÁSICA</th>
        </tr>
      </thead>
      <draggable
        v-model="sessionPlannigList"
        tag="tbody"
        :disabled="user.is_student || !allows_crud"
      >
        <!-- :study_unit_id="studyUnit.id" -->
        <SessionRow
          v-for="(session_planning, index) in sessionPlannigList"
          :SessionPlanning="session_planning"
          :filter_list="filter_list"
          :index="index"
          :key_label="key_label"
          :section_id="section_id"
          :new_matter_id="matter.id"
          :matter_id="matter.matter"
          :allows_crud="allows_crud"
          :key="session_planning.id"
          :BibliographicResources="BibliographicResources"
          :DirectTeachingActivities="DirectTeachingActivities"
          :AutonomousTeachingActivities="AutonomousTeachingActivities"
          :TeachingSupportResource="TeachingSupportResource"
          :EvaluationCriteriaContents="EvaluationCriteriaContents"
          @created_session_planning="createdSessionPlanning"
        ></SessionRow>
        <!-- :SumativeEvaluations="SumativeEvaluations" -->
      </draggable>
      <!-- <template v-else>
        <SessionRow
          v-for="(session_planning, index) in sessionPlannigList"
          :SessionPlanning="session_planning"
          :index="index"
          :study_unit_id="studyUnit.id"
          :section_id="section_id"
          :matter_id="matter.id"
          :allows_crud="allows_crud"
          :key="session_planning.id"
          :BibliographicResources="bibliographic_resources"
          :DirectTeachingActivities="DirectTeachingActivities"
          :AutonomousTeachingActivities="AutonomousTeachingActivities"
          :TeachingSupportResource="TeachingSupportResource"
          :SumativeEvaluations="SumativeEvaluations"
        ></SessionRow>
      </template> -->
    </table>
    <div
      v-if="allows_crud && key_label == 'section'"
      style="margin: 10px 1px; text-align: center"
    >
      <b-button
        variant="secondary"
        class="mr-1"
        size="sm"
        @click="$bvModal.show('modal-observations')"
        >Observaciones del Docente</b-button
      >
      <b-modal
        :title="`Observaciones de la ${$getVisibleNames(
          'teaching.section',
          false,
          'Sección'
        )}: '${section ? section.name : ''}'`"
        hide-footer
        id="modal-observations"
        size="lg"
      >
        <SectionObservations
          :section_id="section_id"
          :allows_crud="allows_crud"
          @saved="$bvModal.hide('modal-observations')"
        ></SectionObservations>
      </b-modal>
    </div>
  </div>
</template>

<script>
import * as names from "@/store/names";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { generateUniqueId } from "@/utils/utils";

export default {
  name: "SessionTable",
  components: {
    draggable,
    SessionRow: () => import("./SessionRow"),
    SectionObservations: () => import("./SectionObservations"),
  },
  props: {
    // study_unit_id: {
    //   type: Number,
    //   required: true,
    // },
    section_id: {
      type: Number,
    },
    egress_profile_matter_id: {
      type: Number,
      required: true,
    },
    matter_id: {
      type: Number,
    },
    key_label: String,
    filter_list: Array,
    DirectTeachingActivities: Array,
    AutonomousTeachingActivities: Array,
    TeachingSupportResource: Array,
    BibliographicResources: Array,
    EvaluationCriteriaContents: Array,
    // SumativeEvaluations: Array,
    allows_crud: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      session_plannig_list: [],
      // bibliographic_resources: [],
    };
  },
  computed: {
    ...mapGetters({
      matters: names.MATTERS,
      egress_profiles_matters: names.EGRESS_PROFILES_MATTERS,
      studyUnits: names.STUDY_UNITS,
      sessionPlannings: names.SESSION_PLANNINGS,
      sections: names.SECTIONS,
      sessionDates: names.SESSION_DATES,
      time_allocations: names.TIME_ALLOCATIONS,
      ep_matter_time_allocations: names.EP_MATTER_TIME_ALLOCATIONS,
      user: "getUser",
    }),
    oldMatter() {
      if (!this.matter) return null;
      return this.matters.find((x) => x.id == this.matter.matter);
    },
    // studyUnit() {
    //   return this.studyUnits.find((x) => x.id == this.study_unit_id);
    // },
    section() {
      return this.sections.find((x) => x.id == this.section_id);
    },
    matter() {
      if (!this.egress_profile_matter_id) return null;
      return this.egress_profiles_matters.find(
        (x) => x.id == this.egress_profile_matter_id
      );
    },
    sessionPlannigList: {
      get() {
        return this.session_plannig_list;
      },
      set(list) {
        for (let index = 0; index < list.length; index++) {
          if (list[index].order != index + 1) {
            try {
              this.patchSessionPlanningOrder(list[index].id, index + 1);
            } catch {
              continue;
            }
          }
          list[index].order = index + 1;
        }
        this.session_plannig_list = list;
      },
    },
    sessionPlanning() {
      const ids_list = this.filter_list.map(
        (object) => object.session_planning
      );
      return this.sessionPlannings.filter((x) => ids_list.includes(x.id));
    },
    // oldMatterWeekHours() {
    //   if (!this.oldMatter) return 0;
    //   return (
    //     this.oldMatter.weeks *
    //     (this.oldMatter.week_theoric_hours +
    //       this.oldMatter.week_lab_hours +
    //       this.oldMatter.week_ground_hours +
    //       this.oldMatter.week_assistantship_hours)
    //   );
    // },
    matterWeekHours() {
      if (!this.matter) return 0;
      let hours = 0;
      const pedagogical_hours_ids = this.time_allocations
        .filter((x) => x.uses_module_minutes == true)
        .map((x) => x.id);
      const matter_hours = this.ep_matter_time_allocations.filter(
        (x) =>
          x.egress_profile_matter == this.matter.id &&
          pedagogical_hours_ids.includes(x.time_allocation) &&
          x.counts_towards_credits == true
      );
      matter_hours.forEach((hour) => {
        hours += parseFloat(hour.hours);
      });
      return Math.round(parseFloat(hours));
    },
    // sessionDateList() {
    //   let list = this.sessionDates.filter((x) => x.section == this.section_id);
    //   return list.sort(function (a, b) {
    //     if (moment(a.start_date) > moment(b.start_date)) return 1;
    //     if (moment(a.end_date) < moment(b.end_date)) return -1;
    //     return 0;
    //   });
    // },
  },
  methods: {
    createdSessionPlanning(object) {
      const index = this.filter_list.findIndex((x) => x.id == object.id);
      if (index != -1) {
        this.filter_list.splice(index, 1, object);
      } else {
        this.filter_list.push(object);
      }
    },
    patchSessionPlanningOrder(session_planning_id, order) {
      if (isNaN(session_planning_id)) return;
      const payload = {
        session_planning_id: session_planning_id,
        item: {
          order: order,
        },
      };
      this.$store.dispatch(names.PATCH_SESSION_PLANNING, payload);
    },
    updateSessionList() {
      if (!this.matter && !this.oldMatter) return [];
      let sessions = [];
      let list = this.sessionPlanning;
      let session_amount = 0;
      // TODO: Se dejo comentado para que no mande error con la nueva estructura.
      // if (this.key_label == "matter") session_amount = this.oldMatterWeekHours;
      if (this.key_label == "matter") session_amount = this.matterWeekHours;
      else session_amount = this.matterWeekHours;
      for (let index = 0; index < session_amount; index++) {
        const element = list.find((x) => x.order == index + 1);
        if (element) {
          sessions.push(element);
        } else {
          sessions.push({
            id: generateUniqueId(),
            order: index + 1,
            date: null,
            // study_unit: this.study_unit_id,
            // section: this.section_id,
            evaluation_criterias_micro: [],
            // performance_indicators: [],
            contents: [],
            evaluation_criteria_macro_contents: [],
            methodological_strategies: [],
            bibliographic_resources: [],
            feedbacks: [],
            direct_teaching_activities: [],
            autonomous_teaching_activities: [],
            teaching_support_resource: [],
            // evaluation: null,
          });
        }
      }
      this.session_plannig_list = sessions;
    },
    // fetchBibliographicResources() {
    //   if (this.matter && this.bibliographic_resources.length == 0) {
    //     this.$restful
    //       .Get(`/teaching/bibliographic-resource/?matter=${this.matter.matter}`)
    //       .then((response) => {
    //         this.bibliographic_resources = response;
    //       });
    //   }
    // },
    // fetchSessionDates() {
    //   if (!this.section) return;
    // this.section.session_dates.forEach((session_date_id) => {
    //   this.$store.dispatch(names.FETCH_SESSION_DATE, session_date_id);
    // });
    // },
  },
  watch: {
    matter: function () {
      // this.fetchBibliographicResources();
    },
    section() {
      // this.fetchSessionDates();
      this.updateSessionList();
    },
  },
  created() {
    if (this.key_label == "matter") {
      this.$store
        .dispatch(names.FETCH_SESSION_PLANNINGS, { matter_id: this.matter_id })
        .then(() => {
          this.updateSessionList();
        });
    }
    if (this.key_label == "ep_matter") {
      this.$store
        .dispatch(names.FETCH_SESSION_PLANNINGS, {
          egress_profile_matter_id: this.egress_profile_matter_id,
        })
        .then(() => {
          this.updateSessionList();
        });
    }
    if (this.key_label == "section") {
      this.$store
        .dispatch(names.FETCH_SESSION_PLANNINGS, {
          section_id: this.section_id,
        })
        .then(() => {
          this.updateSessionList();
        });
    }
    // this.fetchBibliographicResources();
    // <!-- TODO: Se comento mientras se trabaja en las Evaluaciones. -->
    // this.$store.dispatch(
    //   names.FETCH_EVALUATIONS_BY_STUDY_UNIT,
    //   this.study_unit_id
    // );
    // this.$store.dispatch(names.FETCH_CONTENTS, this.study_unit_id);
    // this.$store.dispatch(names.FETCH_METHODOLOGICAL_STRATEGIES);
    // this.$store.dispatch(names.FETCH_EVALUATION_FEEDBACKS);
  },
  mounted() {
    // this.fetchSessionDates();
  },
};
</script>

<style scoped>
table {
  /* position: relative; */
  width: 100%;
  border: 2px solid black;
  font-size: var(--secondary-font-size);
}
th {
  vertical-align: middle;
  border: 1px solid black;
  background-color: var(--primary-color);
  color: var(--primary-font-color);
  padding: 0px 5px;
  position: sticky;
  font-size: var(--secondary-font-size);
}
.td-header {
  font-size: var(--secondary-font-size);
  color: var(--primary-font-color);
  font-weight: bold;
}
.td-data {
  text-align: justify;
  padding-left: 1rem;
}
td {
  border: 1px solid black;
  font-size: var(--thirth-font-size);
  min-width: 20px;
  background-color: var(--secondary-color);
}
p {
  text-align: justify;
}
</style>